$fontFamily: "Montserrat", sans-serif;
/***colors*******/
$wColor: #fff;
$blackgreen: #00a532;
$green: #00e178;
$blackBlue: #003264;
$grey: #87a0aa;
$black: #000;
$greydark: #4d4d4d;
$lightgrey: #f6f7f7;
$middlegrey: #7c7c7c;
$azulFondos: #dbe3e6;
$previewgrey: #e5e5e5;
/***fonts-size*******/
$extralarge: 54px;
$large: 24px;
$largesm: 20px;
$bodyLarge: 18px;
$bodyMedium: 16px;
$bodySmall: 14px;
$bodySmaller: 12px;
$xsSmaller: 11px;

/****break points********/
$mobilexsBreakpoint: 375px;
$mobileBreakpoint: 580px;
$tabletBreakpoint: 992px;
$smBreakpoint: 1024px;
$mdBreakpoint: 1280;
$mdxBreakpoint: 1366;
$lBreakpoint: 1440px;
$xlBreakpoint: 1680px;
$xxlBreakpoint: 1921px;

$spacing-sizes: 230px, 160px, 96px, 50px, 36px, 20px;

$spacingTOPBottom: 50px, 40px, 30px, 25px, 20px, 20px;
$layoutgeneral: 35px, 30px, 26px, 26px, 20px, 20px;
$layoutgeneralBottom: 77px, 66px, 55px, 34px, 24px, 20px;
$spacingTOP: 21px, 18px, 15px;
$spacingBottom: 22px, 19px, 15px;
$listTopBottom: 24px, 16px, 16px, 16px, 15px, 15px;
$listTop: 32px, 28px, 24px, 20px, 20px, 15px;
$listTopMargin: 30px, 26px, 22px, 18px, 18px, 15px;
$groups: 24px, 24px, 22px, 18px, 15px, 12px;
$listbottom: 26px, 22px, 18px, 15px, 15px, 10px;

$marginTitle: 40px, 38px, 36px, 34px, 30px, 30px;
$legales: 79px, 69px, 59px, 49px, 39px, 29px;

$breakpoints: (
  xs: 475px,
  sm: 780px,
  md: 1024px,
  lg: 1365px,
  lg1: 1366px,
  lg2: 1440px,
  lx: 1680px,
  xl: 1920px,
);
$superZindex: 9999;
$viewPorts: 40vh, 35vh, 35vh;
$smVP: 47vh;
$tabVP: 53vh;
$mobVP: 51vh;
$radius: 10px;

@function pow($base, $exponent) {
  $result: 1;
  @for $_ from 1 through $exponent {
    $result: $result * $base;
  }
  @return $result;
}

@mixin some-mixin {
  color: $black;
}
@mixin gridPadding($size) {
  $size-xxl: nth($size, 1);
  padding-left: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    padding-left: $size-xs; //Fallback in px
  }
}
@mixin PaddingTop($size) {
  $size-xxl: nth($size, 1);
  padding-top: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    padding-top: $size-xs; //Fallback in px
  }
}
@mixin PaddingBottom($size) {
  $size-xxl: nth($size, 1);
  padding-bottom: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    padding-bottom: $size-xs; //Fallback in px
  }
}
@mixin MarginTop($size) {
  $size-xxl: nth($size, 1);
  margin-top: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    margin-top: $size-xs; //Fallback in px
  }
}
@mixin MarginBottom($size) {
  $size-xxl: nth($size, 1);
  margin-bottom: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    margin-bottom: $size-xs; //Fallback in px
  }
}
@mixin gridTopBottom($size) {
  $size-xxl: nth($size, 1);
  padding-top: $size-xxl; //F
  padding-bottom: $size-xxl;
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 2);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 2);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 3);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 4);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }

  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 4);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }
}
@mixin spacingTOPBottom($size) {
  $size-xxl: nth($size, 1);
  padding-left: $size-xxl; //Fallback in px
  padding-right: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
}
@mixin fontSizing($rule-map, $breakpoints: $breakpoints) {
  @each $element, $settings-map in $rule-map {
    $selector: map-get($settings-map, selector);

    // write generel rules
    #{$selector} {
      @each $property, $value in map-get($settings-map, all) {
        #{$property}: $value;
      }
    }

    // rules for every breakpoint
    @each $breakpoint, $breakpoint-setting in $breakpoints {
      // only if breakpoint values set for element
      @if map-has-key($settings-map, $breakpoint) {
        // write breakpoints rule
        @media ( min-width: #{$breakpoint-setting} ) {
          #{$selector} {
            @each $property, $value in map-get($settings-map, $breakpoint) {
              #{$property}: $value;
            }
          }
        }
      } //if
    } //each
  } //each
} //mixin
@mixin viewPorts($size) {
  @media (max-width: $tabletBreakpoint) {
    $sizePort: nth($size, 2);
    height: $sizePort;
  }
  @media (max-width: $mobileBreakpoint) {
    $sizePort: nth($size, 3);
    height: $sizePort;
  }
}

.button {
  font-weight: 700;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  line-height: 12px;
  width: 100%;
  word-break: keep-all;
  padding-top: 19px;
  padding-bottom: 19px;
  text-transform: uppercase;
  font-family: $fontFamily;
  @media (max-width: $xlBreakpoint) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @media (max-width: $lBreakpoint) {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  @media (max-width: $mdxBreakpoint) {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  @media (max-width: $smBreakpoint) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  @media (max-width: $tabletBreakpoint) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  @media (max-width: 320px) {
    line-height: 15px;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.25;
    cursor: auto;
  }
}
.buttonPrimary {
  color: $black;
  background: $green;
  border: 1px solid $green;
  &:hover {
    background: $blackBlue;
    border-color: $blackBlue;
    color: $wColor;
  }
}
.buttonSecondary {
  color: #003264;
  background-color: transparent;
  border: 1px solid $blackBlue;
  &:hover {
    color: $black;
    background-color: $azulFondos;
  }
}
.buttonSizesmall {
  font-size: 12px;
  padding: 10px 16px;
}
.buttonSizesmedium {
  font-size: 14px;
  padding: 11px 20px;
}
.buttonSizeslarge {
  font-size: 16px;
  padding: 12px 24px;
}
$rules: (
  title: (
    selector: ".button",
    all: (
      font-size: 10px,
    ),
    xs: (
      font-size: $bodySmaller,
    ),
    xxl: (
      font-size: clamp($bodySmaller, 0.5vw, $bodySmaller * 2),
    ),
  ),
) !default;
@include fontSizing($rules);
