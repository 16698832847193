@import-normalize;
html {
  height: 100%;
  width: 100%;
  height: 100vh;
  margin: 0;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}
body {
  margin: 0;
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  background: $lightgrey;
}
#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* .textarea :focus-visible {
  outline-color: $blackgreen;
}
