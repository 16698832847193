#onetrust-banner-sdk {
  #onetrust-button-group button {
    width: auto !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;

    @media (max-width: 767.98px) {
      width: 100% !important;
      margin-right: 0 !important;
    }
  }

  #onetrust-button-group {
    display: flex !important;
    align-items: center;

    @media (max-width: 767.98px) {
      flex-wrap: wrap;
    }

    #onetrust-accept-btn-handler {
      background-color: #00a532;
      border-color: #00a532;
      color: #ffffff;
      order: 1;
    }

    #onetrust-pc-btn-handler {
      color: #333333;
      border-color: #333333;
      background-color: #FFFFFF;
      order: 2;
    }

    #onetrust-reject-all-handler{
      color: #333333 !important;
      border-color: #333333 !important;
      background-color: #FFFFFF !important;
      border: none !important;
      text-decoration: underline !important;
      order: 3;
    }
  }
}
