$fontFamily: "Montserrat", sans-serif;
/***colors*******/
$wColor: #fff;
$blackgreen: #00a532;
$green: #00e178;
$blackBlue: #003264;
$grey: #87a0aa;
$black: #000;
$greydark: #4d4d4d;
$lightgrey: #f6f7f7;
$middlegrey: #7c7c7c;
$azulFondos: #dbe3e6;
$previewgrey: #e5e5e5;
/***fonts-size*******/
$extralarge: 54px;
$large: 24px;
$largesm: 20px;
$bodyLarge: 18px;
$bodyMedium: 16px;
$bodySmall: 14px;
$bodySmaller: 12px;
$xsSmaller: 11px;

/****break points********/
$mobilexsBreakpoint: 375px;
$mobileBreakpoint: 580px;
$tabletBreakpoint: 992px;
$smBreakpoint: 1024px;
$mdBreakpoint: 1280;
$mdxBreakpoint: 1366;
$lBreakpoint: 1440px;
$xlBreakpoint: 1680px;
$xxlBreakpoint: 1921px;

$spacing-sizes: 230px, 160px, 96px, 50px, 36px, 20px;

$spacingTOPBottom: 50px, 40px, 30px, 25px, 20px, 20px;
$layoutgeneral: 35px, 30px, 26px, 26px, 20px, 20px;
$layoutgeneralBottom: 77px, 66px, 55px, 34px, 24px, 20px;
$spacingTOP: 21px, 18px, 15px;
$spacingBottom: 22px, 19px, 15px;
$listTopBottom: 24px, 16px, 16px, 16px, 15px, 15px;
$listTop: 32px, 28px, 24px, 20px, 20px, 15px;
$listTopMargin: 30px, 26px, 22px, 18px, 18px, 15px;
$groups: 24px, 24px, 22px, 18px, 15px, 12px;
$listbottom: 26px, 22px, 18px, 15px, 15px, 10px;

$marginTitle: 40px, 38px, 36px, 34px, 30px, 30px;
$legales: 79px, 69px, 59px, 49px, 39px, 29px;

$breakpoints: (
  xs: 475px,
  sm: 780px,
  md: 1024px,
  lg: 1365px,
  lg1: 1366px,
  lg2: 1440px,
  lx: 1680px,
  xl: 1920px,
);
$superZindex: 9999;
$viewPorts: 40vh, 35vh, 35vh;
$smVP: 47vh;
$tabVP: 53vh;
$mobVP: 51vh;
$radius: 10px;

@function pow($base, $exponent) {
  $result: 1;
  @for $_ from 1 through $exponent {
    $result: $result * $base;
  }
  @return $result;
}

@mixin some-mixin {
  color: $black;
}
@mixin gridPadding($size) {
  $size-xxl: nth($size, 1);
  padding-left: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    padding-left: $size-xs; //Fallback in px
  }
}
@mixin PaddingTop($size) {
  $size-xxl: nth($size, 1);
  padding-top: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    padding-top: $size-xs; //Fallback in px
  }
}
@mixin PaddingBottom($size) {
  $size-xxl: nth($size, 1);
  padding-bottom: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    padding-bottom: $size-xs; //Fallback in px
  }
}
@mixin MarginTop($size) {
  $size-xxl: nth($size, 1);
  margin-top: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    margin-top: $size-xs; //Fallback in px
  }
}
@mixin MarginBottom($size) {
  $size-xxl: nth($size, 1);
  margin-bottom: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    margin-bottom: $size-xs; //Fallback in px
  }
}
@mixin gridTopBottom($size) {
  $size-xxl: nth($size, 1);
  padding-top: $size-xxl; //F
  padding-bottom: $size-xxl;
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 2);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 2);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 3);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 4);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }

  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 4);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }
}
@mixin spacingTOPBottom($size) {
  $size-xxl: nth($size, 1);
  padding-left: $size-xxl; //Fallback in px
  padding-right: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
}
@mixin fontSizing($rule-map, $breakpoints: $breakpoints) {
  @each $element, $settings-map in $rule-map {
    $selector: map-get($settings-map, selector);

    // write generel rules
    #{$selector} {
      @each $property, $value in map-get($settings-map, all) {
        #{$property}: $value;
      }
    }

    // rules for every breakpoint
    @each $breakpoint, $breakpoint-setting in $breakpoints {
      // only if breakpoint values set for element
      @if map-has-key($settings-map, $breakpoint) {
        // write breakpoints rule
        @media ( min-width: #{$breakpoint-setting} ) {
          #{$selector} {
            @each $property, $value in map-get($settings-map, $breakpoint) {
              #{$property}: $value;
            }
          }
        }
      } //if
    } //each
  } //each
} //mixin
@mixin viewPorts($size) {
  @media (max-width: $tabletBreakpoint) {
    $sizePort: nth($size, 2);
    height: $sizePort;
  }
  @media (max-width: $mobileBreakpoint) {
    $sizePort: nth($size, 3);
    height: $sizePort;
  }
}

.formContent {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  max-width: 100%;

  @media (max-width: $tabletBreakpoint) {
    display: contents;
    display: flex;
    flex-direction: column;
  }
}

.configuration {
  display: grid;
  grid-template-columns: 3fr 1.509fr;
  padding-bottom: 0px;
  overflow: hidden;
  position: relative;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 80px;
  @media (max-width: $xlBreakpoint) {
    top: 77px;
  }
  @media (max-width: $lBreakpoint) {
    top: 72px;
  }
  @media (max-width: $mdBreakpoint) {
    top: 71px;
  }

  @media (max-height: 900px) and (min-width: $smBreakpoint) {
    grid-template-columns: 3fr 2fr;
  }

  .content {
    display: grid;
    height: 100%;
    @media (max-width: $tabletBreakpoint) {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: $tabletBreakpoint) {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    overflow: initial;
    max-height: 100%;
    max-height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 0;
    height: auto;
    padding-bottom: 60px;
  }
  .preview {
    overflow: hidden;
    position: relative;
    border-right: 1px solid rgba(135, 170, 160, 0.2);
    //cursor: url("../../assets/cursor.svg") 40 40, zoom-in;
    @include viewPorts($viewPorts);
    transition: all 0.5s ease-in-out;
    @media (max-width: $tabletBreakpoint) {
      height: calc(100vw / 1.4);
      position: relative;
      border-right: 0;
      z-index: 5;
      position: fixed;
      width: 100%;

      &.scrolling {
        top: 0;
      }
    }
    &.customView {
      @media (max-width: $tabletBreakpoint) {
        height: calc(100vw / 0.9);
      }
    }

    @media (max-width: $tabletBreakpoint) {
      z-index: 5;
    }
    @media (max-width: $mobileBreakpoint) {
      top: 80px;
    }
    &.customView {
      @media (max-width: $mobileBreakpoint) {
        top: 50px;
        height: calc(100vw / 0.8);
      }
      &.offer {
        @media (max-width: $mobileBreakpoint) {
          top: 76px;
          height: calc(100vw / 0.8);
        }
      }
    }
  }
  .menu {
    background: $lightgrey;
    position: relative;
    .menuTopArea {
      background: $lightgrey;
      height: calc(100vh - 184px);

      @media (max-width: $xlBreakpoint) {
        height: calc(var(--vh, 100vh) - 168px);
      }
      @media (max-width: $lBreakpoint) {
        height: calc(var(--vh, 100vh) - 155px);
      }
      @media (max-width: $mdxBreakpoint) {
        height: calc(var(--vh, 100vh) - 155px);
      }
      @media (max-width: $smBreakpoint) {
        height: calc(var(--vh, 100vh) - 155px);
      }
      @media (max-width: $tabletBreakpoint) {
        height: calc(var(--vh, 100vh));
      }

      @include spacingTOPBottom($spacingTOPBottom);
      @media (max-width: $tabletBreakpoint) {
        position: relative;
        bottom: auto;
        z-index: 1;
        padding-top: 0;
        left: 0;
        padding: 0;
        right: 0;
        overflow-y: visible;
        background: $lightgrey;
        top: calc(100vw / 1.4);
        bottom: 60px;
        padding-bottom: 80px;
      }
      &.scrolling {
        @media (max-width: $tabletBreakpoint) {
          top: calc(100vw / 1.4);
          padding-top: 80px;
        }
      }
      &.noDisplay {
        display: none;
      }
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: $tabletBreakpoint) {
      justify-content: flex-start;
      bottom: 0px;
      padding-bottom: 0px;
      top: 0px;
    }
  }
}
