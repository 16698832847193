$fontFamily: "Montserrat", sans-serif;
/***colors*******/
$wColor: #fff;
$blackgreen: #00a532;
$green: #00e178;
$blackBlue: #003264;
$grey: #87a0aa;
$black: #000;
$greydark: #4d4d4d;
$lightgrey: #f6f7f7;
$middlegrey: #7c7c7c;
$azulFondos: #dbe3e6;
$previewgrey: #e5e5e5;
/***fonts-size*******/
$extralarge: 54px;
$large: 24px;
$largesm: 20px;
$bodyLarge: 18px;
$bodyMedium: 16px;
$bodySmall: 14px;
$bodySmaller: 12px;
$xsSmaller: 11px;

/****break points********/
$mobilexsBreakpoint: 375px;
$mobileBreakpoint: 580px;
$tabletBreakpoint: 992px;
$smBreakpoint: 1024px;
$mdBreakpoint: 1280;
$mdxBreakpoint: 1366;
$lBreakpoint: 1440px;
$xlBreakpoint: 1680px;
$xxlBreakpoint: 1921px;

$spacing-sizes: 230px, 160px, 96px, 50px, 36px, 20px;

$spacingTOPBottom: 50px, 40px, 30px, 25px, 20px, 20px;
$layoutgeneral: 35px, 30px, 26px, 26px, 20px, 20px;
$layoutgeneralBottom: 77px, 66px, 55px, 34px, 24px, 20px;
$spacingTOP: 21px, 18px, 15px;
$spacingBottom: 22px, 19px, 15px;
$listTopBottom: 24px, 16px, 16px, 16px, 15px, 15px;
$listTop: 32px, 28px, 24px, 20px, 20px, 15px;
$listTopMargin: 30px, 26px, 22px, 18px, 18px, 15px;
$groups: 24px, 24px, 22px, 18px, 15px, 12px;
$listbottom: 26px, 22px, 18px, 15px, 15px, 10px;

$marginTitle: 40px, 38px, 36px, 34px, 30px, 30px;
$legales: 79px, 69px, 59px, 49px, 39px, 29px;

$breakpoints: (
  xs: 475px,
  sm: 780px,
  md: 1024px,
  lg: 1365px,
  lg1: 1366px,
  lg2: 1440px,
  lx: 1680px,
  xl: 1920px,
);
$superZindex: 9999;
$viewPorts: 40vh, 35vh, 35vh;
$smVP: 47vh;
$tabVP: 53vh;
$mobVP: 51vh;
$radius: 10px;

@function pow($base, $exponent) {
  $result: 1;
  @for $_ from 1 through $exponent {
    $result: $result * $base;
  }
  @return $result;
}

@mixin some-mixin {
  color: $black;
}
@mixin gridPadding($size) {
  $size-xxl: nth($size, 1);
  padding-left: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    padding-left: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    padding-left: $size-xs; //Fallback in px
  }
}
@mixin PaddingTop($size) {
  $size-xxl: nth($size, 1);
  padding-top: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    padding-top: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    padding-top: $size-xs; //Fallback in px
  }
}
@mixin PaddingBottom($size) {
  $size-xxl: nth($size, 1);
  padding-bottom: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    padding-bottom: $size-xs; //Fallback in px
  }
}
@mixin MarginTop($size) {
  $size-xxl: nth($size, 1);
  margin-top: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    margin-top: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    margin-top: $size-xs; //Fallback in px
  }
}
@mixin MarginBottom($size) {
  $size-xxl: nth($size, 1);
  margin-bottom: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    margin-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    margin-bottom: $size-xs; //Fallback in px
  }
}
@mixin gridTopBottom($size) {
  $size-xxl: nth($size, 1);
  padding-top: $size-xxl; //F
  padding-bottom: $size-xxl;
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 2);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 2);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 3);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 4);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }

  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 4);
    padding-top: $size-xs; //Fallback in px
    padding-bottom: $size-xs; //Fallback in px
  }
}
@mixin spacingTOPBottom($size) {
  $size-xxl: nth($size, 1);
  padding-left: $size-xxl; //Fallback in px
  padding-right: $size-xxl; //Fallback in px

  @media (max-width: $xlBreakpoint) {
    $size-xs: nth($size, 2);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $lBreakpoint) {
    $size-xs: nth($size, 3);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $mdxBreakpoint) {
    $size-xs: nth($size, 4);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $smBreakpoint) {
    $size-xs: nth($size, 4);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $tabletBreakpoint) {
    $size-xs: nth($size, 5);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
  @media (max-width: $mobileBreakpoint) {
    $size-xs: nth($size, 6);
    padding-left: $size-xs; //Fallback in px
    padding-right: $size-xs; //Fallback in px
  }
}
@mixin fontSizing($rule-map, $breakpoints: $breakpoints) {
  @each $element, $settings-map in $rule-map {
    $selector: map-get($settings-map, selector);

    // write generel rules
    #{$selector} {
      @each $property, $value in map-get($settings-map, all) {
        #{$property}: $value;
      }
    }

    // rules for every breakpoint
    @each $breakpoint, $breakpoint-setting in $breakpoints {
      // only if breakpoint values set for element
      @if map-has-key($settings-map, $breakpoint) {
        // write breakpoints rule
        @media ( min-width: #{$breakpoint-setting} ) {
          #{$selector} {
            @each $property, $value in map-get($settings-map, $breakpoint) {
              #{$property}: $value;
            }
          }
        }
      } //if
    } //each
  } //each
} //mixin
@mixin viewPorts($size) {
  @media (max-width: $tabletBreakpoint) {
    $sizePort: nth($size, 2);
    height: $sizePort;
  }
  @media (max-width: $mobileBreakpoint) {
    $sizePort: nth($size, 3);
    height: $sizePort;
  }
}

.contentHeaderSelection {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  max-width: calc(2fr); /* Establece el ancho máximo en píxeles */
  overflow: hidden;
  text-overflow: ellipsis;
  background: #fff;
  @include spacingTOPBottom($spacingTOPBottom);
  @media (max-width: $tabletBreakpoint) {
    border: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
  @media (max-width: $mobilexsBreakpoint) {
    padding-left: 10px;
  }
  display: flex;
  justify-content: space-between;
  .parameterSelection {
    display: flex;
    overflow: hidden;
    margin: 0;
    p {
      display: inline-block;
      align-self: center;
    }
    @media (max-width: $tabletBreakpoint) {
      flex-direction: row-reverse;
    }
  }
  .textContent {
    overflow: hidden;
    display: inline-flex;
    padding: 0 20px 0 0;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    & .text {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
  .text {
    font-weight: 700;
    padding: 0;
    margin: 0;
    &.offer_ccr {
      font-weight: 700;
    }

    @media (max-width: $mobileBreakpoint) {
      font-weight: 500;
    }
    @media (min-width: $tabletBreakpoint) {
      &::after {
        content: "|";
        height: 17px;
        font-weight: 300;
        display: inline-block;
        margin: 0 10px;
        color: $grey;
      }
      &.offer_ccr::after {
        content: "";
      }
    }

    @media (max-width: $tabletBreakpoint) {
      &::before {
        content: "|";
        width: 1px;
        height: 17px;
        font-weight: 300;
        display: inline-block;
        margin: 0 12px;
        color: $grey;
      }
      &.offer_ccr::before {
        content: "";
      }
    }
  }
  .back {
    font-weight: 700;
    line-height: 17px;

    a {
      color: $grey;
      display: flex;
      align-items: center;
      text-decoration: none;
      @media (max-width: $tabletBreakpoint) {
        transform: rotate(180deg);
        margin-right: 6px;
      }

      .textlink {
        margin-right: 6px;
        @media (max-width: $smBreakpoint) {
          font-size: 11px;
        }
      }

      svg path {
        fill: $grey;
        @media (max-width: $tabletBreakpoint) {
          fill: $black;
        }
      }
      &:hover {
        color: $blackgreen;
        svg path {
          fill: $blackgreen;
        }
      }
    }
  }
  .imgContent {
    display: inline-block;
    width: 18px;
    height: 18px;
    svg {
      width: 18px;
      height: 18px;
      path {
        stroke-width: 8px;
      }
    }

    img {
      max-width: 100%;
    }
  }
  .back {
    display: inline-block;
    align-self: center;
  }
}

$rules: (
  title: (
    selector: ".text",
    all: (
      font-size: $bodySmaller,
      line-height: 17px,
      font-weight: 500,
    ),
    lg: (
      font-size: $bodySmall,
      line-height: 17px,
      font-weight: 500,
    ),
    lg1: (
      font-size: $bodyMedium,
      line-height: 29px,
    ),
    lg2: (
      font-size: $bodyMedium,
      line-height: 29px,
    ),
    xl: (
      font-size: $bodyLarge,
      line-height: 29px,
    ),
    xxl: (
      font-size: clamp($bodyLarge, 0.8vw, $bodyLarge * 2),
    ),
  ),
  back: (
    selector: ".back",
    all: (
      font-size: $bodySmaller,
      line-height: 17px,
    ),
    lg: (
      font-size: 11px,
      line-height: 17px,
    ),
    lg1: (
      font-size: $bodySmall,
      line-height: 29px,
    ),
    lg2: (
      font-size: $bodySmall,
      line-height: 29px,
    ),
    xl: (
      font-size: $bodySmall,
      line-height: 29px,
    ),
    xxl: (
      font-size: clamp($bodySmall, 0.5vw, $bodySmall * 2),
    ),
  ),
) !default;
@include fontSizing($rules);
